export class DateUtils {
}

declare global {
    export interface Date {
        getYear(): number;
    }
}
Date.prototype.getYear = function (): number {
    return +this.getFullYear().toString().substr(-2);
};
