export class PromiseUtils {
}

declare global {
    export interface PromiseConstructor {
        delay(ms: number): any;
    }
}

Promise.delay = function(ms: number): any {
    return new Promise(resolve => setTimeout(resolve, ms));
};
