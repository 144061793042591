export interface TableColumnsModel {
    id: string;
    sortable?: boolean;
}

export class TableColumns implements TableColumnsModel {
    public id: string;
    public sortable: boolean;

    constructor(id: string, sortable = true) {
        this.id = id;
        this.sortable = sortable;
    }
}
