import { isNullOrUndefined } from "./type.utils";

export namespace ArrayUtils {
    export function joinIfDefined(array: any[], join = " "): string {
        return array.filter(val => val).join(join);
    }

    export function unique<T>(
            array: T[],
            comparator: (value1: T, value2: T) => boolean = (value1: T, value2: T) => value1 === value2
    ): T[] {
        if (isNullOrUndefined(array)) {
            return array;
        }

        const newArray: T[] = [];
        for (const value of array) {
            if (newArray.findIndex((valueInArray) => comparator(valueInArray, value)) < 0) {
                newArray.push(value);
            }
        }

        return newArray;
    }

    export function uniqueObjects<Item>(array: Item[], keySelector: (item: Item) => unknown): Item[] {
        return Array.from(new Map(array.map(x => [keySelector(x), x])).values());
    }

    export function groupBy<Item, Key, Value>(
            array: Item[],
            keySelector: (item: Item) => Key,
            valueSelector: (item: Item) => Value[] | Value
    ): Map<Key, Value[]> {
        let groups: Map<Key, Value[]> = new Map<Key, Value[]>();
        for (let item of array) {
            let key: Key = keySelector(item);
            if (key == null) {
                continue;
            }
            let value: Value[] | Value = valueSelector(item);
            if (value == null) {
                continue;
            }

            if (!(value instanceof Array)) {
                value = [value];
            }

            if (!groups.has(key)) {
                groups.set(key, value);
            } else {
                groups.get(key).push(...value);
            }
        }
        return groups;
    }
}

declare global {
    interface Array<T> {
        distinct<T>(): Array<T>;

        skipNullValues(): T[];

        replace<T>(item: T, ...newItems: T[]): Array<T>;
    }
}

Array.prototype.replace = function <T>(item: T, ...newItems: T[]) {
    return [...this.slice(0, this.indexOf(item)), ...newItems, ...this.slice(this.indexOf(item) + 1, this.length)];
};

Array.prototype.distinct = function() {
    return this.filter((value, index) => {
        return this.indexOf(value) === index;
    });
};

Array.prototype.skipNullValues = function() {
    return this.filter(x => !!x);
};
