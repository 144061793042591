import { TableColumns } from "../components/base-list/models/columns.model";

export class StringUtils {
}

declare global {
    interface String {
        toTableColumn(sortable?: boolean): TableColumns;
    }
}

String.prototype.toTableColumn = function(sortable?: boolean): TableColumns {
    return new TableColumns(this.toString(), sortable);
};
